import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="About" />
    <div className="container full no-margin">
      <div className="container textCenter">
        <h2>About Handlist.dev</h2>
        <p>
          Handlist.dev is a website built for listing most useful resources for
          the developers.
        </p>
        <p>All the lists are hand-picked to ensure the quality the content.</p>
        <p>
          <Link to="/">Back to Home</Link>
        </p>
      </div>
    </div>
  </Layout>
)
